<div class="containeer">
  @switch (windowType()) {
    @case('rating') {
      <div class="feedback-window">
        <div class="feedback-info">
            <div class="feedback-info-title">
                <div class="feedback-info-title-logo">
                    <div class="feedback-info-title-logo-icon">
                        <img src=".\assets\logo.svg" alt="logo" class="logo">
                    </div>
                </div>
                <div class="feedback-info-title-text">Give Us Your Feedback</div>
            </div>
            <div class="feedback-info-description">
                <span class="feedback-info-description-text">
                    Hello! Your feedback helps build up our business & improve our customers experience!
                    Please rate your experience below
                </span>
                <span class="feedback-info-description-icon">
                    😁
                </span>
            </div>
        </div>

        <div class="select-buttons">
            <button class="select-buttons-item select-buttons-styles" (click)="selectRating(1)">
                <span class="select-buttons-item-low">👎🏼</span>
            </button>
            <button class="select-buttons-item select-buttons-styles" (click)="selectRating(3)">
                <img src=".\assets\50-50.svg" alt="logo" class="select-buttons-item-50">
            </button>
            <button class="select-buttons-item select-buttons-styles" (click)="selectRating(5)">
                <span class="select-buttons-item-high">👍🏼</span>
            </button>
        </div>
    </div>
    }
    @case('description') {
      <div class="feedback-window feedback-window2">
        <div class="feedback-info">
            <div class="feedback-info-title">
                <div class="feedback-info-title-logo">
                    <div class="feedback-info-title-logo-icon">
                        <img src=".\assets\logo.svg" alt="logo">
                    </div>
                </div>
                <div class="feedback-info-title-text">Give Us Your Feedback</div>
            </div>
            <div class="feedback-info-description">
                <span class="feedback-info-description-text">
                  @if(ratingValue > 3){
                    Thank you for your feedback. We would really appreciate if you took moment to write a review
                  }@else {
                    We are sorry to hear that you had a bad experience! This feedback goes directly to owners as they work diligently to provide you the best customer experience.
                  }
                </span>
            </div>
        </div>

        <div class="description-input">
            <textarea class="description-input-textarea" (change)="changeDesc($event)"></textarea>
            <button class="btn btn-primary send-btn" (click)="sendFeedback()">Send</button>
        </div>
    </div>
    }
    @case('repost') {
      <div class="feedback-window">
        <div class="feedback-info">
            <div class="feedback-info-title">
                <div class="feedback-info-title-logo">
                    <div class="feedback-info-title-logo-icon">
                        <img src=".\assets\logo.svg" alt="logo" class="logo-icon">
                    </div>
                </div>
                <div class="feedback-info-title-text">Give Us Your Feedback</div>
            </div>
            <div class="feedback-info-description">
                <span class="feedback-info-description-text">
                    Thank you for your feedback. We would really appreciate if you took moment to write a review on one of the following sites. ☺️
                </span>
            </div>
        </div>
        <div class="select-buttons" [ngClass]="linksCount > 2 ? 'justify-between' : ''">
          @if(urlList?.bbb?.active){
            <button class="select-buttons-item select-buttons-styles" (click)="selectSite('bbb')">
              <img src=".\assets\bbb-icon.png" alt="bbb-icon" class="select-buttons-item-middle bbb-adaptive">
            </button>
          }
          @if(urlList?.google?.active){
            <button class="select-buttons-item select-buttons-styles platform" (click)="selectSite('google')">
              <img src=".\assets\google-icon.svg" alt="google-icon" class="select-buttons-item-middle">
              <span>Google</span>
          </button>
          }
          @if(urlList?.yelp?.active){
            <button class="select-buttons-item select-buttons-styles platform" (click)="selectSite('yelp')">
              <img src=".\assets\yelp-icon.svg" alt="yelp-icon" class="select-buttons-item-middle">
              <span>Yelp</span>
          </button>
          }
          @if(urlList?.transportReviews?.active){
            <button class="select-buttons-item select-buttons-styles" (click)="selectSite('transportReviews')">
              <img src=".\assets\transport-reviews-icon.png" alt="transport-reviews-icon" class="select-buttons-item-middle transport-reviews-adaptive">
          </button>
          }
          @if(urlList?.trustpilot?.active){
            <button class="select-buttons-item select-buttons-styles platform" (click)="selectSite('trustpilot')">
              <img src=".\assets\trustpilot-icon.svg" alt="tustpilot-icon" class="select-buttons-item-middle trustpilot">
              <span>Trustpilot</span>
          </button>
          }
        </div>
    </div>
    }
    @case('thanks') {
      <div class="feedback-window feedback-window2">
        <div class="feedback-info ">

            <img src=".\assets\check-circle.svg" alt="check-circle" class="check-icon">

            <div class="feedback-info-title column">
                <div class="feedback-info-title-logo">
                    <div class="feedback-info-title-logo-icon">
                        <img src=".\assets\logo-green.svg" alt="logo-green" class="logo-green">
                    </div>
                </div>
                <div class="feedback-info-title-text green">Thank You For Your Feedback</div>
            </div>
            <div class="feedback-info-description flex justify-center">
                <span class="feedback-info-description-text green feedback-desc">
                    Your feedback & word of mouth builds up our business!
                    <br/>Thank you & God bless!
                </span>
            </div>
        </div>
    </div>
    }
  }
</div>
