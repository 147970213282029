const unescape = str => {
  return (str + '==='.slice((str.length + 3) % 4))
    .replace(/-/g, '+')
    .replace(/_/g, '/');
};

const escape = str => {
  return str
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

export function encodeUrlSafe(str) {
  return escape(btoa(str));
}

export function decodeUrlSafe(str) {
  return atob(unescape(str));
}
